function calculateOrganicWashFold({
  weight,
  products,
  settings,
  isExpressDelivery,
}) {
  try {
    // let productsWithPrices = [];

    let {
      organicWashFoldMinDelivery,
      organicWashFoldUpToXLbs,
      organicWashFoldPerLb,
    } = settings.settings;

    let price =
      organicWashFoldMinDelivery +
      parseInt(weight - organicWashFoldUpToXLbs) * organicWashFoldPerLb;

    if (isExpressDelivery) {
      price = price + 0.4 * weight;
    }
    return {
      price: price,
    };
  } catch (err) {
    console.log(err);
  }
}

module.exports = calculateOrganicWashFold;
