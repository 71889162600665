const { CompositionPage } = require("twilio/lib/rest/video/v1/composition");

function calculateWashFold({
  weight,
  products,
  settings,
  isFlorida,
  isExpressDelivery,
}) {
  let productsWithPrices = [];

  let {
    washFoldMinDelivery,
    washFoldUpToXLbs,
    washFoldPerLb,
  } = settings.settings;

  let price =
    washFoldMinDelivery + parseInt(weight - washFoldUpToXLbs) * washFoldPerLb;

  if (isExpressDelivery) {
    price = price + 0.4 * weight;
  }

  if (products) {
    settings.products.forEach((p) => {
      if (products[p.item]) {
        price += p.price;
        productsWithPrices.push([p.item, p.price.toFixed(2)]);
      }
    });
  }

  return { price, products: productsWithPrices };
}

module.exports = calculateWashFold;
