import axios from "axios";

import {
  CREATE_ORDER,
  GET_ORDERS,
  GET_ORDER,
  ASSIGN_ORDER_LOCATION,
  UPDATE_ORDER_STATUS,
  RESET_ORDER_STATE,
  ORDER_ERROR,
  CLEAR_ORDER_ERROR,
  UPDATE_ORDER_SERVICE,
  UPDATE_ORDER_SCHEDULE,
  UPDATE_ORDER_PAYMENT,
  EXPORT_ORDERS,
  CLEAR_CSV,
  UPDATE_ORDER,
} from "./types";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const createOrder = (order, user, callback) => {
  order.user = user;

  if (
    order.washFoldOrder?.isExpressDelivery ||
    order.organicWashFoldOrder?.isExpressDelivery
  ) {
    order.isExpressDelivery = true;
  }

  return (dispatch) => {
    return axios
      .post(`/api/order`, order, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearOrderError());
        dispatch(createOrderSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(orderError(err.response.data.message));
        } else {
          dispatch(orderError("There was an error creating this order."));
        }
      });
  };
};

export const createOrderSuccess = (data) => ({
  type: CREATE_ORDER,
  order: data,
});

// Get all orders
export const getOrders = (sortBy, limit, skip) => {
  let order = sortBy.desc === true ? -1 : 1;
  let uri = `/api/orders?sortby=${sortBy.key}&sortorder=${order}&skip=${skip}&limit=${limit}`;

  return (dispatch) => {
    return axios
      .get(uri, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearOrderError());
        dispatch(getOrdersSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(orderError(err.response.data.message));
        } else {
          dispatch(orderError("There was an error getting orders."));
        }
      });
  };
};

// Get all orders
export const exportOrders = (orders) => {
  return (dispatch) => {
    if (process.env.NODE_ENV === "development") {
      console.log("exportOrders", orders);
    }

    return axios
      .post(
        `/api/orders/export`,
        { orders: orders },
        {
          headers: { Authorization: "JWT " + localStorage.getItem("token") },
        }
      )
      .then((response) => {
        if (response.data.error) {
          dispatch(orderError(response.data.error));
        } else {
          dispatch(exportOrdersSuccess(response.data));
          dispatch(clearOrderError());
        }
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const exportOrdersSuccess = (data) => ({
  type: EXPORT_ORDERS,
  csv: data,
});

export const getOrdersSuccess = (data) => ({
  type: GET_ORDERS,
  orders: data,
});

export function clearCSV() {
  return {
    type: CLEAR_CSV,
  };
}

// Get a single order
export const getOrder = (orderId) => {
  return (dispatch) => {
    if (process.env.NODE_ENV === "development") {
      console.log("getOrder");
    }
    return axios
      .get(`/api/order/${orderId}`, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        dispatch(getOrderSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getOrderSuccess = (data) => ({
  type: GET_ORDER,
  order: data,
});

// Update the location of an order
export const assignOrderLocation = (formData, callback) => {
  return function(dispatch) {
    if (process.env.NODE_ENV === "development") {
      console.log("assignOrderLocation");
    }

    axios
      .post(`/api/order/location`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(orderError(response.data.error));
        } else {
          dispatch(clearOrderError());
          dispatch(assignOrderLocationSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};

export const assignOrderLocationSuccess = (data) => ({
  type: ASSIGN_ORDER_LOCATION,
  order: data,
});

// Update the status of an order
export const updateOrderStatus = (formData, callback) => {
  return function(dispatch) {
    axios
      .post(`/api/order/status`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(orderError(response.data.error));
        } else {
          dispatch(clearOrderError());
          dispatch(updateOrderStatusSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};

export const updateOrderStatusSuccess = (data) => ({
  type: UPDATE_ORDER_STATUS,
  order: data,
});

// Update an order
export const updateOrderService = (formData, callback) => {
  return function(dispatch) {
    if (process.env.NODE_ENV === "development") {
      console.log("updateOrderService");
    }
    axios
      .post(`/api/order/service`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(orderError(response.data.error));
        } else {
          dispatch(clearOrderError());
          dispatch(updateOrderServiceSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};

export const updateOrderServiceSuccess = (data) => ({
  type: UPDATE_ORDER_SERVICE,
  order: data,
});

// Update an order schedule
export const updateOrderSchedule = (formData, callback) => {
  return function(dispatch) {
    axios
      .post(`/api/order/schedule`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearOrderError());
        dispatch(updateOrderScheduleSuccess(data));
        callback();
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(orderError(err.response.data.message));
        } else {
          dispatch(orderError("There was an error getting orders."));
        }
      });
  };
};

export const updateOrderScheduleSuccess = (data) => ({
  type: UPDATE_ORDER_SCHEDULE,
  order: data,
});

export const searchOrders = (sortBy, searchText) => {
  if (searchText) {
    let order = sortBy.desc === true ? -1 : 1;
    return (dispatch) => {
      if (process.env.NODE_ENV === "development") {
        console.log("searchOrders");
      }
      return axios
        .get(
          `/api/orders/search?sortby=${sortBy.key}&sortorder=${order}&searchtext=${searchText}`,
          {
            headers: { Authorization: "JWT " + localStorage.getItem("token") },
          }
        )
        .then((response) => {
          dispatch(getOrdersSuccess(response.data));
        })
        .catch((error) => {
          throw error;
        });
    };
  }
};

export const filterOrders = (formData) => {
  return (dispatch) => {
    if (process.env.NODE_ENV === "development") {
      console.log("searchOrders");
    }
    return axios
      .post(`/api/orders/filter`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        dispatch(getOrdersSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export function resetOrderState() {
  return {
    type: RESET_ORDER_STATE,
  };
}

export function orderError(error) {
  return {
    type: ORDER_ERROR,
    payload: error,
  };
}

export function clearOrderError() {
  return {
    type: CLEAR_ORDER_ERROR,
  };
}

export const updateOrderPayment = (formData, callback) => {
  return function(dispatch) {
    if (process.env.NODE_ENV === "development") {
      console.log("updateOrderPayment");
    }
    axios
      .post(`/api/payment/payment`, formData, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(orderError(response.data.error));
        } else {
          dispatch(clearOrderError());
          dispatch(updateOrderPaymentSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};

export const updateOrderPaymentSuccess = (data) => ({
  type: UPDATE_ORDER_PAYMENT,
  order: data,
});

// Submit changes to order
export const updateOrder = (order, user, callback) => {
  order.user = user;

  return function(dispatch) {
    if (process.env.NODE_ENV === "development") {
      console.log("updateOrder");
    }

    axios
      .put(`/api/order`, order, {
        headers: { Authorization: "JWT " + localStorage.getItem("token") },
      })
      .then((response) => {
        if (response.data.error) {
          dispatch(orderError(response.data.error));
        } else {
          dispatch(clearOrderError());
          dispatch(updateOrderSuccess(response.data));
          callback();
        }
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};

export const updateOrderSuccess = (data) => ({
  type: UPDATE_ORDER,
  order: data,
});
