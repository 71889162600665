import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// Utils
import calculateOrganicWashFold from "../../../../../utils/calculateOrganicWashFold";

// Redux
import { updateLocalOrder } from "../../../../../actions/localOrder";
import { updateEditOrder } from "../../../../../actions/editOrder";

// Form
import { useForm, ErrorMessage } from "react-hook-form";

function OrganicWashFold(props) {
  const [order, setOrder] = useState();
  const [organicWashFoldMinDelivery, setOrganicWashFoldMinDelivery] = useState(
    32
  );
  const [organicWashFoldUpToXLbs, setOrganicWashFoldUpToXLbs] = useState(20);
  const [organicWashFoldPerLb, setOrganicWashFoldPerLb] = useState(1.6);
  const [defaultWeight, setDefaultWeight] = useState();
  // const [products, setProducts] = useState(null);
  const [isFlorida, setIsFlorida] = useState(false);

  // Refactor later
  useEffect(() => {
    if (props.editOrder?.orderId && props.auth.authenticated) {
      setOrder(props.editOrder);

      if (props.editOrder.address?.state.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    } else {
      setOrder(props.localOrder);

      if (props.localOrder?.address?.state.toLowerCase() == "fl") {
        setIsFlorida(true);
      } else {
        setIsFlorida(false);
      }
    }
  }, [props.editOrder?.address, props.localOrder?.address]);

  // Default weight from order
  useEffect(() => {
    if (order && order.organicWashFoldOrder) {
      setDefaultWeight(order.organicWashFoldOrder.weight);
    }
  }, [order]);

  useEffect(() => {
    if (isFlorida) {
      setOrganicWashFoldMinDelivery(
        props.settingsFlorida.settings.organicWashFoldMinDelivery
      );
      setOrganicWashFoldUpToXLbs(
        props.settingsFlorida.settings.organicWashFoldUpToXLbs
      );
      setOrganicWashFoldPerLb(
        props.settingsFlorida.settings.organicWashFoldPerLb
      );
    } else {
      setOrganicWashFoldMinDelivery(
        props.settings.settings.organicWashFoldMinDelivery
      );
      setOrganicWashFoldUpToXLbs(
        props.settings.settings.organicWashFoldUpToXLbs
      );
      setOrganicWashFoldPerLb(props.settings.settings.organicWashFoldPerLb);
    }
  }, [props.settings, props.settingsFlorida, isFlorida]);

  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
  });

  const weight = watch("weight");
  // const selectedProducts = watch("products");
  const history = useHistory();

  const onSubmit = (data) => {
    let { price } = calculateOrganicWashFold({
      weight: weight || order.organicWashFoldOrder.weight,
      settings: isFlorida ? props.settingsFlorida : props.settings,
      isExpressDelivery: order.isExpressDelivery,
    });

    data.quote = price.toFixed(2);
    // data.productsWithPrices = products;

    if (props.editOrder && props.editOrder.orderId) {
      props.dispatch(
        updateEditOrder(data, "organicWashFoldOrder", () => {
          history.push("/app/order/checkout");
        })
      );
    } else {
      props.dispatch(
        updateLocalOrder(data, "organicWashFoldOrder", () => {
          history.push("/app/order/schedule");
        })
      );
    }
  };

  return (
    <div id="organic-wash-fold-step" className="order-step">
      <h4 className="step-header">
        Approximately how many pounds is your organic wash & fold order?
      </h4>

      <form id="order-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-line">
          <input
            type="number"
            name="weight"
            min={0}
            defaultValue={defaultWeight}
            ref={register({
              required:
                "Please provide your best estimate. You will be charged for the exact weight once we receive your items.",
            })}
          />{" "}
          lbs
          <ErrorMessage errors={errors} name="weight">
            {({ message }) => <p className="form-error-message">{message}</p>}
          </ErrorMessage>
          {weight < organicWashFoldUpToXLbs && (
            <p className="form-error-message">
              For pick up / delivery orders under {organicWashFoldUpToXLbs} lbs,
              you will be charged the minimum price of $
              {organicWashFoldMinDelivery}.
            </p>
          )}
        </div>

        <div className="action-button-container">
          <input
            className="submit-button bg-green"
            type="submit"
            value={props.organicWashFoldOrder ? "Update" : "Continue"}
          />
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    settings: state.settings,
    settingsFlorida: state.settingsFlorida,
    localOrder: state.localOrder,
    editOrder: state.editOrder,
  };
};
export default connect(mapStateToProps)(OrganicWashFold);
